import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import _ from 'lodash';
import PortfolioContext from '../../context/context';
import HeroImg from '../Image/HeroImg';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, cta, img } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="hero" className="jumbotron">
      {!_.isEmpty(hero) && (
        <Container>
          <Row className="hero-wrapper">
            <Col lg={8} md={6} sm={12}>
              <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
                <h1 className="hero-title">
                  {title} <span className="text-color-main">{name}</span>
                  <br />
                  {subtitle}
                </h1>
              </Fade>
              <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1500} distance="30px">
                <p className="hero-cta">
                  <span className="cta-btn cta-btn--hero">
                    <Link to="about" smooth duration={1000}>
                      {cta}
                    </Link>
                  </span>
                </p>
              </Fade>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Fade bottom duration={1000} delay={1000} distance="30px">
                <div className="hero-wrapper__image">
                  <HeroImg alt="hero image" filename={img} />
                </div>
              </Fade>
            </Col>
          </Row>
        </Container>
      )}
    </section>
  );
};

export default Header;
